<template>
  <div class="pb-3">
    <!-- 按鈕 -->
    <div
      class="px-3 row"
      :class="{
        'justify-content-between': currentPage === 'BatchCreateMerchandise',
        'justify-content-end': currentPage !== 'BatchCreateMerchandise',
      }"
    >
      <div class="col-md-5 col-12 p-0 mb-3">
        <button
          class="tw-btn tw-btn-success"
          @click="addField"
          v-if="currentPage === 'BatchCreateMerchandise'"
        >
          新增欄位
        </button>
        <span v-if="currentPage === 'BatchCreateMerchandise'">｜</span>
        <button
          class="tw-btn tw-btn-secondary me-2"
          @click="showModal('closePage')"
        >
          關閉
        </button>
        <button class="tw-btn tw-btn-success" @click="createMerchandises(null)">
          確認建立商品
        </button>
      </div>
      <!-- 分頁 -->
      <nav
        class="d-flex justify-content-center col-md-7 col-12 p-0"
        style="height: 50px; overflow-y: scroll"
      >
        <ul class="pagination" style="flex-wrap: wrap">
          <li
            class="page-item"
            :class="{
              disabled: currentIndex === 1,
              pointer: currentIndex !== 1,
              'not-allowed': currentIndex === 1,
            }"
          >
            <a class="page-link" @click="switchPage(currentIndex - 1)"
              >上個商品</a
            >
          </li>
          <li
            :class="{ active: currentIndex === page }"
            class="page-item pointer"
            aria-current="page"
            v-for="page in totolPage"
            :key="page"
          >
            <a class="page-link" @click.prevent="switchPage(page)">{{
              page
            }}</a>
          </li>
          <li
            class="page-item"
            :class="{
              disabled: currentIndex === merchandises.length,
              pointer: currentIndex !== merchandises.length,
              'not-allowed': currentIndex === merchandises.length,
            }"
          >
            <a class="page-link" @click="switchPage(currentIndex + 1)"
              >下個商品</a
            >
          </li>
        </ul>
      </nav>
    </div>
    <!-- 新增商品區塊 -->
    <div
      class="border border-3 my-2 mx-md-2 rounded rounded-5 merchArea"
      style="height: calc(100vh - 200px); overflow-y: scroll"
    >
      <template v-if="loadingRecord === -1">
        <template v-if="merchandises.length > 0">
          <template v-for="(data, index) in merchandises" :key="data.id">
            <div class="tw-container m-0 p-1" v-if="index + 1 === currentIndex">
              <!-- 操作按鈕 -->
              <div class="mb-3">
                <span
                  class="fw-bolder p-2 me-3"
                  :class="{
                    'alert-success': data.hasComments,
                    'alert-danger': !data.hasComments,
                  }"
                  v-if="currentPage === 'SynchronizeFBArticle'"
                  >{{ data.hasComments ? "有留言" : "沒有留言" }}</span
                >
                <button
                  class="tw-btn tw-btn-secondary me-3"
                  @click="copyMerhandise(data)"
                  v-if="currentPage === 'BatchCreateMerchandise'"
                >
                  複製
                </button>
                <button
                  class="tw-btn tw-btn-secondary me-3"
                  @click="pasteMerchandise(data)"
                  v-if="currentPage === 'BatchCreateMerchandise'"
                >
                  貼上
                </button>
                <button
                  class="tw-btn tw-btn-danger me-3"
                  @click="delMerchandise(data.id)"
                >
                  刪除
                </button>
                <button
                  class="tw-btn tw-btn-success"
                  @click="createMerchandises(data)"
                  v-if="
                    currentPage !== 'BatchCreateMerchandise' &&
                    currentPage !== 'CopyMerchandise'
                  "
                >
                  單筆建立商品
                </button>
              </div>
              <!-- 文章連結 -->
              <a
                :href="data.permalink_url"
                target="_blank"
                rel="noopener noreferrer"
                class="fw-bolder"
                v-if="data.permalink_url"
              >
                點我前往此文章 !
              </a>
              <hr />
              <!-- 商品內容 / 商品分類 / 供應商 / 狀態 -->
              <div class="row">
                <!-- 商品內容 part1 -->
                <div class="col-md-6 col-12 mb-5 p-0 pe-1">
                  <!-- FB 文章建商品設定 -->
                  <div
                    v-if="
                      currentPage !== 'BatchCreateMerchandise' &&
                      currentPage !== 'CopyMerchandise'
                    "
                  >
                    <div class="form-check form-switch p-0">
                      <input
                        class="form-check-input m-0 me-2"
                        type="checkbox"
                        role="switch"
                        :id="`LinkFbArticle${data.id}`"
                        v-model="data.createFeedLink"
                      />
                      <label
                        class="form-check-label"
                        :for="`LinkFbArticle${data.id}`"
                        >關聯FB文章</label
                      >
                    </div>
                    <div class="form-check form-switch p-0">
                      <input
                        class="form-check-input m-0 me-2"
                        type="checkbox"
                        role="switch"
                        :id="`AutoCreateOrders${data.id}`"
                        v-model="data.autoCreateMerchOrderFromFb"
                      />
                      <label
                        class="form-check-label"
                        :for="`AutoCreateOrders${data.id}`"
                        >留言自動成立訂單</label
                      >
                    </div>
                    <hr />
                  </div>
                  <p class="tw-border-start fw-bolder mb-3">商品內容</p>
                  <!-- 商品名稱 -->
                  <div class="form-floating mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="MerchandiseName"
                      placeholder="商品名稱(100字)"
                      maxlength="100"
                      v-model="data.merchandise.name"
                    />
                    <label class="text-danger" for="MerchandiseName"
                      >商品名稱(100字)*</label
                    >
                  </div>
                  <!-- 商品描述 -->
                  <div class="form-floating mb-3">
                    <textarea
                      class="form-control"
                      placeholder="商品描述(2000字)"
                      id="MerchandiseDescription"
                      style="height: 300px"
                      maxlength="2000"
                      v-model="data.merchandise.description"
                    ></textarea>
                    <label for="MerchandiseDescription">商品描述(2000字)</label>
                  </div>
                  <!-- VIP / 販售狀態 -->
                  <div class="row mb-3">
                    <!-- VIP -->
                    <div class="col-sm-6 p-0 pe-sm-1 mb-2 mb-sm-0">
                      <template v-if="!storeVips.loading">
                        <select
                          class="form-control"
                          name="merchandiseVip"
                          v-model="data.merchandise.storeVipId"
                        >
                          <option :value="null">不設定 VIP</option>
                          <template
                            v-for="(vip, index) in storeVips.data"
                            :key="vip.id"
                          >
                            <option :value="vip.id" v-if="vip.id <= storeVip">
                              LV{{ index + 1 }}: {{ vip.name }}
                            </option>
                          </template>
                        </select>
                      </template>
                      <AreaLoading v-else></AreaLoading>
                    </div>
                    <!-- 販售狀態 -->
                    <div class="col-sm-6 p-0 ps-sm-1">
                      <select
                        class="form-control"
                        name="merchandiseSellState"
                        v-model="data.merchandise.sellState"
                      >
                        <option value="CANPREORDER">可預購</option>
                        <option value="STOCKONLY">僅供現貨</option>
                        <option value="LIMITED">限量</option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- 商品分類 / 供應商 / 狀態 -->
                <div class="col-md-6 col-12 mb-5 p-0 ps-1">
                  <!-- 商品分類 -->
                  <div>
                    <p class="tw-border-start fw-bolder mb-3">商品分類</p>
                    <div
                      style="height: 100px; overflow-y: scroll"
                      v-if="!merchClassifications.loading"
                    >
                      <template v-if="merchClassifications.data.length > 0">
                        <div class="row">
                          <div
                            class="form-check p-0 col-6"
                            v-for="merchClassification in merchClassifications.data"
                            :key="merchClassification.id"
                          >
                            <input
                              class="form-check-input m-0 me-2"
                              type="checkbox"
                              :value="merchClassification.id"
                              :id="`CreateCategory${merchClassification.id}_${data.id}`"
                              v-model="data.merchClassificationIds"
                            />
                            <label
                              class="form-check-label text-break"
                              :for="`CreateCategory${merchClassification.id}_${data.id}`"
                            >
                              {{ merchClassification.name }}
                            </label>
                          </div>
                        </div>
                      </template>
                      <p class="text-danger fw-bolder" v-else>
                        您目前尚未有任何商品分類!
                      </p>
                    </div>
                    <AreaLoading v-else></AreaLoading>
                  </div>
                  <hr />
                  <!-- 供應商 -->
                  <div>
                    <p class="tw-border-start fw-bolder mb-3">供應商</p>
                    <div>
                      <div class="row mb-2">
                        <div
                          class="form-check p-0 col"
                          @click="clearSupplierInfo(data)"
                        >
                          <!-- 不選擇供應商 -->
                          <input
                            class="form-check-input m-0 me-2"
                            type="radio"
                            :name="`selectSupplier${data.id}`"
                            :id="`NoSupplier${data.id}`"
                            value="不選擇供應商"
                            v-model="data.supplierStatus"
                          />
                          <label
                            class="form-check-label"
                            :for="`NoSupplier${data.id}`"
                          >
                            不選擇供應商
                          </label>
                        </div>
                        <!-- 選擇現有供應商 -->
                        <div
                          class="form-check p-0 col"
                          @click="clearSupplierInfo(data)"
                        >
                          <input
                            class="form-check-input m-0 me-2"
                            type="radio"
                            :name="`selectSupplier${data.id}`"
                            :id="`SelectDefaltSupplier${data.id}`"
                            value="選擇現有供應商"
                            v-model="data.supplierStatus"
                          />
                          <label
                            class="form-check-label"
                            :for="`SelectDefaltSupplier${data.id}`"
                          >
                            選擇現有供應商
                          </label>
                        </div>
                        <!-- 自行輸入供應商 -->
                        <div
                          class="form-check p-0 col"
                          @click="clearSupplierInfo(data)"
                          v-if="
                            (currentParticipantPermission &&
                              currentParticipantPermission.accessSupplier) ||
                            !currentParticipantPermission
                          "
                        >
                          <input
                            class="form-check-input m-0 me-2"
                            type="radio"
                            :name="`selectSupplier${data.id}`"
                            :id="`SetOwnSupplier${data.id}`"
                            value="自行輸入供應商"
                            v-model="data.supplierStatus"
                          />
                          <label
                            class="form-check-label"
                            :for="`SetOwnSupplier${data.id}`"
                          >
                            自行輸入供應商
                          </label>
                        </div>
                      </div>
                      <!-- 選擇現有供應商畫面 -->
                      <div v-if="data.supplierStatus === '選擇現有供應商'">
                        <div class="mb-2" v-if="!suppliers.loading">
                          <template v-if="suppliers.data.length > 0">
                            <select
                              class="form-control"
                              v-model="data.supplierLinks.name"
                            >
                              <option value="" disabled>請選擇供應商</option>
                              <option
                                :value="supplier.name"
                                v-for="supplier in suppliers.data"
                                :key="supplier.id"
                              >
                                {{ supplier.name }}
                              </option>
                            </select>
                          </template>
                          <p class="text-danger fw-bolder" v-else>
                            您目前尚未有任何供應商!
                          </p>
                        </div>
                        <AreaLoading v-else></AreaLoading>
                        <div class="form-floating mb-3">
                          <input
                            onwheel="this.blur()"
                            type="number"
                            class="form-control"
                            id="CreateD4SupplierCost"
                            placeholder="成本"
                            min="0"
                            v-model="data.supplierLinks.cost"
                            @input="setD4CostInMainStyles(data)"
                            @change="
                              data.supplierLinks.cost = $methods.numberToFixed(
                                data.supplierLinks.cost
                              )
                            "
                          />
                          <label for="CreateD4SupplierCost">成本</label>
                        </div>
                      </div>
                      <!-- 自行輸入供應商畫面 -->
                      <div v-else-if="data.supplierStatus === '自行輸入供應商'">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            :id="`CreateOwnSupplier${data.id}`"
                            placeholder="供應商(20字)"
                            maxlength="20"
                            v-model="data.supplierLinks.name"
                          />
                          <label :for="`CreateOwnSupplier${data.id}`"
                            >供應商(20字)</label
                          >
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            onwheel="this.blur()"
                            type="number"
                            class="form-control"
                            :id="`CreateOwnSupplierCost${data.id}`"
                            placeholder="成本"
                            min="0"
                            v-model="data.supplierLinks.cost"
                            @input="setD4CostInMainStyles(data)"
                            @change="
                              data.supplierLinks.cost = $methods.numberToFixed(
                                data.supplierLinks.cost
                              )
                            "
                          />
                          <label :for="`CreateOwnSupplierCost${data.id}`"
                            >成本</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- 商品狀態 -->
                  <div>
                    <p class="tw-border-start fw-bolder mb-3">商品狀態</p>
                    <!-- 上下架 -->
                    <div class="form-check form-switch p-0 mb-2">
                      <input
                        class="form-check-input m-0 me-2"
                        type="checkbox"
                        role="switch"
                        :id="`MerchandiseReady${data.id}`"
                        v-model="data.merchandise.ready"
                      />
                      <label
                        class="form-check-label"
                        :for="`MerchandiseReady${data.id}`"
                        >上架</label
                      >
                    </div>
                    <!-- 顧客自行結單 -->
                    <div
                      class="form-check form-switch p-0 mb-2"
                      v-if="storeInfo.canCheckOut"
                    >
                      <input
                        class="form-check-input m-0 me-2"
                        type="checkbox"
                        role="switch"
                        :id="`MerchandiseCanCheckOut${data.id}`"
                        v-model="data.merchandise.checkOut"
                      />
                      <label
                        class="form-check-label"
                        :for="`MerchandiseCanCheckOut${data.id}`"
                        >顧客可自行結單</label
                      >
                    </div>
                    <!-- 收單時間 -->
                    <div class="mb-2">
                      <!-- 開關 -->
                      <div class="form-check form-switch p-0 mb-2">
                        <input
                          class="form-check-input m-0 me-2"
                          type="checkbox"
                          role="switch"
                          :id="`DeadLineTimeSwitch${data.id}`"
                          v-model="data.deadLineTimeSwitch"
                        />
                        <label
                          class="form-check-label"
                          :for="`DeadLineTimeSwitch${data.id}`"
                          >開啟收單時間</label
                        >
                      </div>
                      <!-- 時間 -->
                      <div class="form-floating" v-if="data.deadLineTimeSwitch">
                        <input
                          class="form-control"
                          type="datetime-local"
                          :id="`MerchandiseDeadLineTime${data.id}`"
                          name="merchandiseDeadLineTime"
                          placeholder="收單時間"
                          v-model="data.merchandise.deadLineTime"
                        />
                        <label
                          :for="`MerchandiseDeadLineTime${data.id}`"
                          class="mb-2"
                          >收單時間</label
                        >
                      </div>
                    </div>
                    <!-- 到貨時間 -->
                    <div class="mb-2">
                      <!-- 開關 -->
                      <div class="form-check form-switch p-0 mb-2">
                        <input
                          class="form-check-input m-0 me-2"
                          type="checkbox"
                          role="switch"
                          :id="`ArrivalTimeSwitch${data.id}`"
                          v-model="data.arrivalTimeSwitch"
                        />
                        <label
                          class="form-check-label"
                          :for="`ArrivalTimeSwitch${data.id}`"
                          >開啟到貨時間</label
                        >
                      </div>
                      <!-- 時間 -->
                      <div class="form-floating" v-if="data.arrivalTimeSwitch">
                        <input
                          class="form-control"
                          type="datetime-local"
                          :id="`MerchandiseArrivalTime${data.id}`"
                          name="merchandiseArrivalTime"
                          placeholder="到貨時間"
                          v-model="data.merchandise.arrivalTime"
                        />
                        <label
                          :for="`MerchandiseArrivalTime${data.id}`"
                          class="mb-2"
                          >到貨時間</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 商品圖片 -->
              <div class="mb-5">
                <p class="tw-border-start fw-bolder mb-2">
                  商品圖片
                  <span class="text-danger fw-bold"
                    >(圖片大小若大於10M，系統將會自動過濾)</span
                  >
                </p>
                <!-- 圖片大小 > 10M 的錯誤 -->
                <div
                  class="alert-danger fw-bold p-1 mb-2"
                  v-if="pic_errorSize.length > 0"
                >
                  <p class="text-break">
                    檔案大小超過 10M:
                    <span
                      v-for="(fileName, index) in pic_errorSize"
                      :key="`size${index}`"
                    >
                      {{ fileName }}
                      <span v-if="index < pic_errorSize.length - 1">，</span>
                    </span>
                  </p>
                </div>
                <!-- 圖片類型錯誤 -->
                <div
                  class="alert-danger fw-bold p-1 mb-2"
                  v-if="pic_errorType.length > 0"
                >
                  <p class="text-break">
                    檔案類型錯誤:
                    <span
                      v-for="(fileName, index) in pic_errorType"
                      :key="`size${index}`"
                    >
                      {{ fileName }}
                      <span v-if="index < pic_errorType.length - 1">，</span>
                    </span>
                  </p>
                </div>
                <!-- 上傳檔案 -->
                <div
                  class="input-group mb-3"
                  v-if="currentPage === 'BatchCreateMerchandise'"
                >
                  <input
                    :id="`Pictures${data.id}`"
                    type="file"
                    accept="image/jpeg, image/png"
                    multiple
                    class="form-control"
                    @change="priviewPicture(data, `Pictures${data.id}`)"
                  />
                </div>
                <!-- 圖片卡片 -->
                <template v-if="data.pictureLinks.length > 0">
                  <Draggable
                    v-model="data.pictureLinks"
                    class="draggable d-flex flex-wrap pointer"
                    @start="drag = true"
                    @end="drag = false"
                    item-key="id"
                    tag="div"
                  >
                    <template #item="{ element }">
                      <div
                        class="border d-flex flex-column p-2"
                        style="width: 200px"
                      >
                        <img
                          :src="element.url"
                          style="width: 180px; height: 180px"
                        />
                        <div
                          class="d-flex align-items-center justify-content-between mt-2"
                        >
                          <div>
                            <span
                              class="text-success fw-bolder"
                              v-if="element.front"
                              >此為首圖</span
                            >
                            <button
                              class="tw-btn tw-btn-success"
                              v-else
                              @click="setFront(element, data)"
                            >
                              首圖
                            </button>
                          </div>
                          <img
                            class="pointer"
                            src="@/assets/icon/trash-2.png"
                            style="width: 20px; height: 20px"
                            @click="delImg(element, data)"
                          />
                        </div>
                      </div>
                    </template>
                  </Draggable>
                </template>
                <p class="p-3 fw-bolder alert-info text-center" v-else>
                  無商品圖片
                </p>
              </div>
              <!-- 主要款式 / 細項一 -->
              <div class="row">
                <!-- 主要款式 -->
                <div class="col-md-6 col-12 mb-5 alert-success pt-2">
                  <p class="tw-border-start fw-bolder mb-3">
                    主要款式(複製貼上僅限主要款式)
                  </p>
                  <div class="mb-2">
                    <button
                      class="tw-btn tw-btn-success me-3"
                      @click="createStyles('mainStyles', data)"
                    >
                      新增欄位
                    </button>
                    <button
                      class="tw-btn tw-btn-success me-3"
                      type="button"
                      @click="showCanvas('D4SystemCanvas', 'mainStyles', data)"
                    >
                      批次新增
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary me-3"
                      @click="copyStyles('mainStyles', data)"
                    >
                      複製
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary me-3"
                      @click="pasteStyles('mainStyles', data)"
                    >
                      貼上
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary fw-bolder me-2"
                      @click="
                        addComma(
                          `MainStyle${data.id}`,
                          data,
                          'mainStylesBatchAddOwn'
                        )
                      "
                    >
                      <img
                        title="逗號"
                        class="icon"
                        src="@/assets/icon/comma.png"
                        alt="，"
                      />
                    </button>
                  </div>
                  <div class="mb-2 d-flex">
                    <input
                      :id="`MainStyle${data.id}`"
                      class="form-control"
                      type="text"
                      placeholder='批次新增請在各細項名稱中間加上"，"'
                      v-model="data.mainStylesBatchAddOwn"
                      @keyup.space="
                        addComma(
                          `MainStyle${data.id}`,
                          data,
                          'mainStylesBatchAddOwn'
                        )
                      "
                      @keyup.enter="batchAddOwnStyles('mainStyles', data)"
                    />
                    <input
                      onwheel="this.blur()"
                      :id="`MainStylePrice${data.id}`"
                      class="form-control w-25"
                      type="number"
                      placeholder="單價"
                      v-model="data.mainStylePrice"
                      @input="
                        data.mainStylePrice = $methods.numberToFixed(
                          data.mainStylePrice
                        )
                      "
                      @keyup.enter="batchAddOwnStyles('mainStyles', data)"
                    />
                    <input
                      onwheel="this.blur()"
                      :id="`MainStyleCost${data.id}`"
                      class="form-control w-25"
                      type="number"
                      placeholder="成本"
                      v-model="data.mainStyleCost"
                      @input="
                        data.mainStyleCost = $methods.numberToFixed(
                          data.mainStyleCost
                        )
                      "
                      @keyup.enter="batchAddOwnStyles('mainStyles', data)"
                    />
                    <button
                      class="tw-btn tw-btn-success"
                      @click="batchAddOwnStyles('mainStyles', data)"
                    >
                      新增
                    </button>
                  </div>
                  <table class="table table-hover abc">
                    <thead>
                      <tr>
                        <th>拖曳</th>
                        <th style="width: 228px">主要款式(20字)</th>
                        <th style="min-width: 65px">
                          <span>單價</span>
                          <input
                            class="w-75"
                            v-model="data.batchUpdateValue_price"
                            type="text"
                            @input="batchUpdatePriceOrCost('price', data)"
                          />
                        </th>
                        <th style="min-width: 65px">
                          <span>成本</span>
                          <input
                            class="w-75"
                            v-model="data.batchUpdateValue_cost"
                            type="text"
                            @input="batchUpdatePriceOrCost('cost', data)"
                          />
                        </th>
                        <th>刪除</th>
                      </tr>
                    </thead>
                    <Draggable
                      v-model="data.mainStyles"
                      class="draggable bg-light"
                      @start="drag = true"
                      @end="drag = false"
                      item-key="id"
                      tag="tbody"
                      handle=".handle"
                      @change="combineStyles(data)"
                    >
                      <template #item="{ element }">
                        <tr class="mainStyles">
                          <td
                            class="text-center pointer handle"
                            style="line-height: 35px"
                          >
                            <i class="bi bi-arrows-move"></i>
                          </td>
                          <td class="text-break">
                            <input
                              class="form-control"
                              type="text"
                              v-model="element.name"
                              maxlength="20"
                              @input="combineStyles(data)"
                            />
                          </td>
                          <td>
                            <input
                              onwheel="this.blur()"
                              class="form-control"
                              type="number"
                              min="0"
                              v-model="element.price"
                              @input="updateStyles(element, data)"
                              @change="
                                element.price = $methods.numberToFixed(
                                  element.price
                                )
                              "
                            />
                          </td>
                          <td>
                            <input
                              onwheel="this.blur()"
                              class="me-2 form-control"
                              type="number"
                              min="0"
                              v-model="element.cost"
                              @input="updateStyles(element, data)"
                              @change="
                                element.cost = $methods.numberToFixed(
                                  element.cost
                                )
                              "
                            />
                          </td>
                          <td>
                            <img
                              class="pointer"
                              src="@/assets/icon/trash-2.png"
                              style="width: 20px; height: 20px"
                              @click="delStyles('mainStyles', data, element.id)"
                            />
                          </td>
                        </tr>
                      </template>
                    </Draggable>
                  </table>
                </div>
                <!-- 細項一 -->
                <div class="col-md-6 col-12 mb-5 alert-primary pt-2">
                  <p class="tw-border-start fw-bolder mb-3">
                    細項一(複製貼上可在任一細項使用)
                  </p>
                  <div class="mb-2">
                    <button
                      class="tw-btn tw-btn-success me-3"
                      @click="createStyles('oneStyles', data)"
                    >
                      新增欄位
                    </button>
                    <button
                      class="tw-btn tw-btn-success me-3"
                      type="button"
                      @click="showCanvas('D4SystemCanvas', 'oneStyles', data)"
                    >
                      批次新增
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary me-3"
                      @click="copyStyles('oneStyles', data)"
                    >
                      複製
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary me-3"
                      @click="pasteStyles('oneStyles', data)"
                    >
                      貼上
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary fw-bolder me-2"
                      @click="
                        addComma(
                          `OneStyle${data.id}`,
                          data,
                          'oneStylesBatchAddOwn'
                        )
                      "
                    >
                      <img
                        title="逗號"
                        class="icon"
                        src="@/assets/icon/comma.png"
                        alt="，"
                      />
                    </button>
                  </div>
                  <div class="mb-2 d-flex">
                    <input
                      :id="`OneStyle${data.id}`"
                      class="form-control"
                      type="text"
                      placeholder='批次新增請在各細項名稱中間加上"，"'
                      v-model="data.oneStylesBatchAddOwn"
                      @keyup.space="
                        addComma(
                          `OneStyle${data.id}`,
                          data,
                          'oneStylesBatchAddOwn'
                        )
                      "
                      @keyup.enter="batchAddOwnStyles('oneStyles', data)"
                    />
                    <button
                      class="tw-btn tw-btn-success"
                      @click="batchAddOwnStyles('oneStyles', data)"
                    >
                      新增
                    </button>
                  </div>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>拖曳</th>
                        <th style="width: 228px">細項一(20字)</th>
                        <th>刪除</th>
                      </tr>
                    </thead>
                    <Draggable
                      v-model="data.oneStyles"
                      class="draggable bg-light"
                      @start="drag = true"
                      @end="drag = false"
                      item-key="id"
                      tag="tbody"
                      handle=".handle"
                      @change="combineStyles(data)"
                    >
                      <template #item="{ element }">
                        <tr>
                          <td
                            class="text-center pointer handle"
                            style="line-height: 35px"
                          >
                            <i class="bi bi-arrows-move"></i>
                          </td>
                          <td class="text-break">
                            <input
                              class="form-control"
                              type="text"
                              v-model="element.name"
                              maxlength="20"
                              placeholder="最多20字"
                              @input="combineStyles(data)"
                            />
                          </td>
                          <td>
                            <img
                              class="pointer"
                              src="@/assets/icon/trash-2.png"
                              style="width: 20px; height: 20px"
                              @click="delStyles('oneStyles', data, element.id)"
                            />
                          </td>
                        </tr>
                      </template>
                    </Draggable>
                  </table>
                </div>
              </div>
              <!-- 細項二 / 細項三 -->
              <div class="row">
                <!-- 細項二 -->
                <div
                  v-if="data.oneStyles.length > 0"
                  :id="`twoStyles${data.id}`"
                  class="col-md-6 col-12 mb-5 alert-primary pt-2"
                >
                  <p class="tw-border-start fw-bolder mb-3">
                    細項二(複製貼上可在任一細項使用)
                  </p>
                  <div class="mb-2">
                    <button
                      class="tw-btn tw-btn-success me-3"
                      @click="createStyles('twoStyles', data)"
                    >
                      新增欄位
                    </button>
                    <button
                      class="tw-btn tw-btn-success me-3"
                      type="button"
                      @click="showCanvas('D4SystemCanvas', 'twoStyles', data)"
                    >
                      批次新增
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary me-3"
                      @click="copyStyles('twoStyles', data)"
                    >
                      複製
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary me-3"
                      @click="pasteStyles('twoStyles', data)"
                    >
                      貼上
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary fw-bolder me-2"
                      @click="
                        addComma(
                          `TwoStyle${data.id}`,
                          data,
                          'twoStylesBatchAddOwn'
                        )
                      "
                    >
                      <img
                        title="逗號"
                        class="icon"
                        src="@/assets/icon/comma.png"
                        alt="，"
                      />
                    </button>
                  </div>
                  <div class="mb-2 d-flex">
                    <input
                      :id="`TwoStyle${data.id}`"
                      class="form-control"
                      type="text"
                      placeholder='批次新增請在各細項名稱中間加上"，"'
                      v-model="data.twoStylesBatchAddOwn"
                      @keyup.space="
                        addComma(
                          `TwoStyle${data.id}`,
                          data,
                          'twoStylesBatchAddOwn'
                        )
                      "
                      @keyup.enter="batchAddOwnStyles('twoStyles', data)"
                    />
                    <button
                      class="tw-btn tw-btn-success"
                      @click="batchAddOwnStyles('twoStyles', data)"
                    >
                      新增
                    </button>
                  </div>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>拖曳</th>
                        <th style="width: 228px">細項二(20字)</th>
                        <th>刪除</th>
                      </tr>
                    </thead>
                    <Draggable
                      v-model="data.twoStyles"
                      class="draggable bg-light"
                      @start="drag = true"
                      @end="drag = false"
                      item-key="id"
                      tag="tbody"
                      handle=".handle"
                      @change="combineStyles(data)"
                    >
                      <template #item="{ element }">
                        <tr>
                          <td
                            class="text-center pointer handle"
                            style="line-height: 35px"
                          >
                            <i class="bi bi-arrows-move"></i>
                          </td>
                          <td class="text-break">
                            <input
                              class="form-control"
                              type="text"
                              v-model="element.name"
                              maxlength="20"
                              placeholder="最多20字"
                              @input="combineStyles(data)"
                            />
                          </td>
                          <td>
                            <img
                              class="pointer"
                              src="@/assets/icon/trash-2.png"
                              style="width: 20px; height: 20px"
                              @click="delStyles('twoStyles', data, element.id)"
                            />
                          </td>
                        </tr>
                      </template>
                    </Draggable>
                  </table>
                </div>
                <!-- 細項三 -->
                <div
                  v-if="data.twoStyles.length > 0"
                  :id="`threeStyles${data.id}`"
                  class="col-md-6 col-12 mb-5 alert-success pt-2"
                >
                  <p class="tw-border-start fw-bolder mb-3">
                    細項三(複製貼上可在任一細項使用)
                  </p>
                  <div class="mb-2">
                    <button
                      class="tw-btn tw-btn-success me-3"
                      @click="createStyles('threeStyles', data)"
                    >
                      新增欄位
                    </button>
                    <button
                      class="tw-btn tw-btn-success me-3"
                      type="button"
                      @click="showCanvas('D4SystemCanvas', 'threeStyles', data)"
                    >
                      批次新增
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary me-3"
                      @click="copyStyles('threeStyles', data)"
                    >
                      複製
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary me-3"
                      @click="pasteStyles('threeStyles', data)"
                    >
                      貼上
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary fw-bolder me-2"
                      @click="
                        addComma(
                          `ThreeStyle${data.id}`,
                          data,
                          'threeStylesBatchAddOwn'
                        )
                      "
                    >
                      <img
                        title="逗號"
                        class="icon"
                        src="@/assets/icon/comma.png"
                        alt="，"
                      />
                    </button>
                  </div>
                  <div class="mb-2 d-flex">
                    <input
                      :id="`ThreeStyle${data.id}`"
                      class="form-control"
                      type="text"
                      placeholder='批次新增請在各細項名稱中間加上"，"'
                      v-model="data.threeStylesBatchAddOwn"
                      @keyup.space="
                        addComma(
                          `ThreeStyle${data.id}`,
                          data,
                          'threeStylesBatchAddOwn'
                        )
                      "
                      @keyup.enter="batchAddOwnStyles('threeStyles', data)"
                    />
                    <button
                      class="tw-btn tw-btn-success"
                      @click="batchAddOwnStyles('threeStyles', data)"
                    >
                      新增
                    </button>
                  </div>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>拖曳</th>
                        <th style="width: 228px">細項三(20字)</th>
                        <th>刪除</th>
                      </tr>
                    </thead>
                    <Draggable
                      v-model="data.threeStyles"
                      class="draggable bg-light"
                      @start="drag = true"
                      @end="drag = false"
                      item-key="id"
                      tag="tbody"
                      handle=".handle"
                      @change="combineStyles(data)"
                    >
                      <template #item="{ element }">
                        <tr>
                          <td
                            class="text-center pointer handle"
                            style="line-height: 35px"
                          >
                            <i class="bi bi-arrows-move"></i>
                          </td>
                          <td class="text-break">
                            <input
                              class="form-control"
                              type="text"
                              v-model="element.name"
                              maxlength="20"
                              placeholder="最多20字"
                              @input="combineStyles(data)"
                            />
                          </td>
                          <td>
                            <img
                              class="pointer"
                              src="@/assets/icon/trash-2.png"
                              style="width: 20px; height: 20px"
                              @click="
                                delStyles('threeStyles', data, element.id)
                              "
                            />
                          </td>
                        </tr>
                      </template>
                    </Draggable>
                  </table>
                </div>
              </div>
              <!-- 細項四 / 庫存數 / 限量數 -->
              <div class="row">
                <!-- 細項四 -->
                <div
                  v-if="data.threeStyles.length > 0"
                  :id="`fourStyles${data.id}`"
                  class="col-md-6 col-12 mb-5 alert-success pt-2"
                >
                  <p class="tw-border-start fw-bolder mb-3">
                    細項四(複製貼上可在任一細項使用)
                  </p>
                  <div class="mb-2">
                    <button
                      class="tw-btn tw-btn-success me-3"
                      @click="createStyles('fourStyles', data)"
                    >
                      新增欄位
                    </button>
                    <button
                      class="tw-btn tw-btn-success me-3"
                      type="button"
                      @click="showCanvas('D4SystemCanvas', 'fourStyles', data)"
                    >
                      批次新增
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary me-3"
                      @click="copyStyles('fourStyles', data)"
                    >
                      複製
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary me-3"
                      @click="pasteStyles('fourStyles', data)"
                    >
                      貼上
                    </button>
                    <button
                      class="tw-btn tw-btn-secondary fw-bolder me-2"
                      @click="
                        addComma(
                          `FourStyle${data.id}`,
                          data,
                          'fourStylesBatchAddOwn'
                        )
                      "
                    >
                      <img
                        title="逗號"
                        class="icon"
                        src="@/assets/icon/comma.png"
                        alt="，"
                      />
                    </button>
                  </div>
                  <div class="mb-2 d-flex">
                    <input
                      :id="`FourStyle${data.id}`"
                      class="form-control"
                      type="text"
                      placeholder='批次新增請在各細項名稱中間加上"，"'
                      v-model="data.fourStylesBatchAddOwn"
                      @keyup.space="
                        addComma(
                          `FourStyle${data.id}`,
                          data,
                          'fourStylesBatchAddOwn'
                        )
                      "
                      @keyup.enter="batchAddOwnStyles('fourStyles', data)"
                    />
                    <button
                      class="tw-btn tw-btn-success"
                      @click="batchAddOwnStyles('fourStyles', data)"
                    >
                      新增
                    </button>
                  </div>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>拖曳</th>
                        <th style="width: 228px">細項四(20字)</th>
                        <th>刪除</th>
                      </tr>
                    </thead>
                    <Draggable
                      v-model="data.fourStyles"
                      class="draggable bg-light"
                      @start="drag = true"
                      @end="drag = false"
                      item-key="id"
                      tag="tbody"
                      handle=".handle"
                      @change="combineStyles(data)"
                    >
                      <template #item="{ element }">
                        <tr>
                          <td
                            class="text-center pointer handle"
                            style="line-height: 35px"
                          >
                            <i class="bi bi-arrows-move"></i>
                          </td>
                          <td class="text-break">
                            <input
                              class="form-control"
                              type="text"
                              v-model="element.name"
                              maxlength="20"
                              placeholder="最多20字"
                              @input="combineStyles(data)"
                            />
                          </td>
                          <td>
                            <img
                              class="pointer"
                              src="@/assets/icon/trash-2.png"
                              style="width: 20px; height: 20px"
                              @click="delStyles('fourStyles', data, element.id)"
                            />
                          </td>
                        </tr>
                      </template>
                    </Draggable>
                  </table>
                </div>
                <!-- 庫存數 / 限量數 -->
                <div class="col-md-6 col-12 alert-primary pt-2 mb-5">
                  <p class="tw-border-start fw-bolder mb-2">庫存數 / 限量數</p>
                  <table
                    class="table table-hover"
                    v-if="!data.conbineStylesErrorMsg"
                  >
                    <thead>
                      <tr>
                        <th style="width: 300px">款式</th>
                        <th style="min-width: 65px">庫存數</th>
                        <th style="min-width: 65px">限量數</th>
                      </tr>
                    </thead>
                    <tbody class="bg-light">
                      <tr v-for="(stock, index) in data.stocks" :key="index">
                        <td>
                          <span
                            v-for="(style, index) in stock.styles"
                            :key="index"
                          >
                            {{ style.name }}&ensp;
                          </span>
                        </td>
                        <td>
                          <input
                            onwheel="this.blur()"
                            class="form-control"
                            type="number"
                            v-model="stock.stock.quantity"
                            pattern="[0-9]*"
                            oninput="this.value=this.value.replace(/\D/g,'')"
                          />
                        </td>
                        <td>
                          <input
                            onwheel="this.blur()"
                            class="form-control"
                            type="number"
                            v-model="stock.stock.limitedQuantity"
                            pattern="[0-9]*"
                            oninput="this.value=this.value.replace(/\D/g,'')"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="text-danger fw-bolder" v-else>
                    {{ data.conbineStylesErrorMsg }}
                  </p>
                </div>
              </div>
              <!-- 商品內容 part2 -->
              <div>
                <!-- 商品備註 -->
                <div class="form-floating mb-3">
                  <textarea
                    class="form-control"
                    placeholder="商品備註(300字)"
                    id="MerchandiseNote"
                    style="height: 100px"
                    maxlength="300"
                    v-model="data.merchandise.note"
                  ></textarea>
                  <label for="MerchandiseNote"
                    >商品備註(僅現賣家看到)(300字)</label
                  >
                </div>
                <!-- 自定義編號 -->
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="MerchandiseNumber"
                    placeholder="自定義編號(20字)"
                    maxlength="20"
                    v-model="data.merchandise.number"
                  />
                  <label for="MerchandiseNumber">自定義編號(20字)</label>
                </div>
                <!-- 自定義標籤 -->
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="MerchandiseLabel"
                    placeholder="自定義標籤(15字)"
                    maxlength="15"
                    v-model="data.merchandise.label"
                  />
                  <label for="MerchandiseLabel">自定義標籤(15字)</label>
                </div>
                <!-- 倉儲位置 -->
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="MerchandiseLocal"
                    placeholder="倉儲位置(10字)"
                    maxlength="10"
                    v-model="data.merchandise.storageLocation"
                  />
                  <label for="floatingInput">倉儲位置(10字)</label>
                </div>
                <!-- 成團數 -->
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    id="MerchandiseStartSaleQuantity"
                    placeholder="成團數"
                    min="0"
                    v-model="data.merchandise.startSaleQuantity"
                    pattern="[0-9]*"
                    oninput="this.value=this.value.replace(/\D/g,'')"
                  />
                  <label for="MerchandiseStartSaleQuantity">成團數</label>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <p
            class="alert-danger text-center fw-bolder p-3"
            v-if="currentPage === 'SynchronizeFBArticle'"
          >
            您目前沒有任何文章 !
          </p>
        </template>
      </template>
      <div class="d-flex justify-content-center align-items-center" v-else>
        <p
          class="alert-success fw-bolder p-3"
          v-if="currentPage === 'SynchronizeFBArticle'"
        >
          請稍後~正在為您準備資料...
        </p>
      </div>
    </div>
  </div>
  <!-- 關閉頁面的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="closePageModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">警告</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="fw-bodler alert alert-danger text-danger fw-bolder p-3">
            確定關閉此頁 ? 關閉後將不會保留目前資料
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-danger" @click="close">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
  <D4SystemCanvas
    :showD4SystemCanvas="showD4SystemCanvas"
    @notifyParentFromD4SystemCanvas="notifyParentFromD4SystemCanvas"
  ></D4SystemCanvas>
</template>

<script>
// mixin
import { HandleImages } from '../../methods/mixins/handleImages'
import { convertStyleTo2D } from '../../methods/mixins/arrayTo2D'
import { serialArray } from '../../methods/mixins/serialArray'
import { Permission } from '@/methods/mixins/permission';
// draggable
import Draggable from "vuedraggable";
// components
import D4SystemCanvas from '../canvas/D4SystemStyles.vue'
// methods
import { handleImageHash2Url } from "@/methods/mixins/imageHash2Url.js"

export default {
  components: {Draggable, D4SystemCanvas},
  data() {
    return {
      // modal
      closePageModal: {},
      // canvas
      showD4SystemCanvas: false,
      // data
      currentPage: '',
      serverToken: '',
      storeId: 0,
      merchandises: [],
      storeVips: {
        loading: false,
        data: []
      },
      // 處理圖片的錯誤
      pic_errorSize: [],
      pic_errorType: [],
      // 商品分類
      merchClassifications: {
        loading: false,
        data: []
      },
      // 供應商
      suppliers: {
        loading: false,
        data: []
      },
      // 複製紀錄
      copyData: {
        merchandise: {},
        mainStyles: [],
        styles: [],
      },
      // 批次新增系統預設款式
      batchAddOwnStylesData: {
        forWitchMerchandise: {},
        forWhitchStyles: ''
      },
      // * 複製商品
      merchandiseIds: [],
      ownerStoreIds: [],
      // * 批次建立 FB 文章
      communityType: 35,
      feeds: [],
      loadingRecord: 0,
      // * FB 擴充建立商品
      fbPostId: null,
      postInfo: null,
      // * 分頁
      currentIndex: 1,
      totolPage: 1,
    }
  },
  mixins: [HandleImages, convertStyleTo2D, serialArray, Permission],
  mounted() {
    this.initialization()
    this.getStoreInfo()
    this.createModals(["closePageModal"])
  },
  watch: {
    merchandises: {
      handler(val, oldVal) {
        console.log(val)
        // 計算頁數
        this.countPage()
        val.forEach(item => {
          // 判斷是否做排列組合
          if (item.recordLength.mainStyles !== item.mainStyles.length ||
            item.recordLength.oneStyles !== item.oneStyles.length ||
            item.recordLength.twoStyles !== item.twoStyles.length ||
            item.recordLength.threeStyles !== item.threeStyles.length ||
            item.recordLength.fourStyles !== item.fourStyles.length
          ) {
            item.recordLength.mainStyles = item.mainStyles.length
            item.recordLength.oneStyles = item.oneStyles.length
            item.recordLength.twoStyles = item.twoStyles.length
            item.recordLength.threeStyles = item.threeStyles.length
            item.recordLength.fourStyles = item.fourStyles.length
            // 排列組合組款式
            this.combineStyles(item)
          }
        })
      },
      deep: true
    },
    // 判斷我的文章是否都已取得完畢
    loadingRecord(val) {
      // 0: API已做完
      // -1: 文章整理完
      if (val === 0) this.sortoutFeeds()
    },
    // FB 擴充文章資訊
    postInfo: {
      handler(val) {
        if (val) this.sortoutPostInfo()
      },
      deep: true
    },
  },
  methods: {
    showModal(status) {
      if (status === 'closePage') {
        // 關閉頁面
        this.closePageModal.show()
      }
    }, 
    initialization() {
      this.currentPage = this.$route.name
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      if (this.currentPage === 'SynchronizeFBArticle') {
        // 如果是建立 FB 文章的頁面
        if (!this.$route.query || !this.$route.query.data) {
          alert('請按正常方式進入此頁')
          return this.$router.push({name: Merchandise})
        }
        // 搜尋的時間區間做每隔兩天為一組，為了避免取回資料太多，API中斷
        let timeRages = []
        const searchData = JSON.parse(this.$route.query.data)[0]
        let startTime = searchData.timeScope.since
        let endTime = searchData.timeScope.until
        let currentStartTime = this.$methods.moment(startTime).subtract(1, 'days').format('YYYY/MM/DD HH:mm:ss')
        while (new Date(currentStartTime) * 1 < endTime) {
          let object = {
            startTime: null,
            endTime: null
          }
          object.startTime = this.$methods.moment(currentStartTime).add(1, 'days').format('YYYY/MM/DD HH:mm:ss');
          currentStartTime = this.$methods.moment(currentStartTime).add(2, 'days').format('YYYY/MM/DD HH:mm:ss');
          new Date(currentStartTime) < endTime ? object.endTime = currentStartTime : object.endTime = this.$methods.moment(endTime).format('YYYY/MM/DD HH:mm:ss')
          timeRages.push(object)
        }
        console.log(timeRages)
        this.communityType = searchData.communityType
        this.syncFbMerchIntoStore(searchData, timeRages)
      } else if (this.currentPage === 'SyncFbExtentionArticle') {
        // FB 擴充建立商品
        if (!this.$route.query || !this.$route.query.fbPostId) {
          alert('請按正常方式進入此頁')
          return this.$router.push({name: Merchandise})
        }
        this.fbPostId = this.$route.query.fbPostId
        this.getExtentionsPost()
      } else if (this.currentPage === 'CopyMerchandise') {
        // 複製商品
        if (!this.$route.query || !this.$route.query.merchandiseIds || !this.$route.query.ownerStoreIds) {
          alert('請按正常方式進入此頁')
          window.close()
          return
        }
        this.merchandiseIds = this.$route.query.merchandiseIds.split(',')
        this.ownerStoreIds = this.$route.query.ownerStoreIds.split(',')
        this.getMerchandiseInfo()
      } else {
        // 如果是批次建立商品頁面
        this.loadingRecord = -1
        this.merchandises.push(this.merchandiseObject())
        this.combineStyles(this.merchandises[0])
      }
    },
    // 計算分頁
    countPage() {
      this.totolPage = this.merchandises.length
    },
    // 切換頁面
    switchPage(whitchOnePage) {
      // 變更目前頁數
      this.currentIndex = whitchOnePage
      // 將畫面移置最上方
      this.scollToTop()
    },
    // 將畫面移置最上方
    scollToTop() {
      const merchArea = document.getElementsByClassName('merchArea')
      merchArea[0].scrollTo(0, 0)
    },
    // 商品物件
    merchandiseObject() {
      return {
        id: new Date() * 1,
        supplierStatus: '不選擇供應商',
        deadLineTimeSwitch: false,
        arrivalTimeSwitch: false,
        merchandise: {
          storeId: this.storeId,
          name: '',
          description: '',
          storeVipId: null,
          ready: true,
          sellState: 'CANPREORDER',
          number: '',
          label: '',
          startSaleQuantity: 0,
          deadLineTime: null,
          storageLocation: '',
          checkOut: true,
          note: '',
          arrivalTime: null
        },
        pictureLinks: [],
        merchClassificationIds: [],
        supplierLinks: {
          name: '',
          cost: 0,
          preset: true
        },
        styles: [],
        stocks: [],
        mainStyles: [{
          id: `ms_${new Date() * 1}`,
          name: '一個',
          cost: 0,
          price: 0,
        }],
        batchUpdateValue_price: 0,
        batchUpdateValue_cost: 0,
        mainStylesBatchAddOwn: '',
        mainStylePrice: '',
        mainStyleCost: '',
        oneStylesBatchAddOwn: '',
        twoStylesBatchAddOwn: '',
        threeStylesBatchAddOwn: '',
        fourStylesBatchAddOwn: '',
        oneStyles: [],
        twoStyles: [],
        threeStyles: [],
        fourStyles: [],
        // 用來紀錄各款式舊的長度，若新的長度 !== 舊的長度就要做排列組合
        recordLength: {
          mainStyles: 1,
          oneStyles: 0,
          twoStyles: 0,
          threeStyles: 0,
          fourStyles: 0
        },
        // 排列組合若沒照款式階層走就顯示錯誤訊息
        conbineStylesErrorMsg: '',
        // FB 同步文章建立商品用的
        createFeedLink: true,
        autoCreateMerchOrderFromFb: true,
        fbFeedUrl: null,
        hasComments: false,
        permalink_url: null
      }
    },
    // 開啟 canvas
    showCanvas(status, item, data) {
      if (status === 'D4SystemCanvas') {
        this.batchAddOwnStylesData = {
          forWitchMerchandise: data,
          forWhitchStyles: item
        }
        this.showD4SystemCanvas = true
      }
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreVips,getMerchClassifications,getSuppliers}'
        }
      ]
      this.storeInfo = {}
      this.storeVips = {
        loading: true,
        data: []
      }
      this.merchClassifications = {
        loading: true,
        data: []
      }
      this.suppliers = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            // storeVip
            vm.storeVip = storeInfo.storeVipId ? storeInfo.storeVipId : 0
            vm.storeVips = {
              loading: false,
              data: storeInfo.storeVips
            }
            // 商品分類
            vm.merchClassifications = {
              loading: false,
              data: vm.merchClassificationsFilter(storeInfo.merchClassifications)
            }
            // 供應商
            vm.suppliers = {
              loading: false,
              data: storeInfo.suppliers
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // * 複製商品 (start)
    // 取得商品資訊
    getMerchandiseInfo() {
      this.$methods.switchLoading('show')
      const vm = this;
      const getMerchandiseInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`;
      const header = {
        authorization: this.serverToken
      };
      const data = [
        {
          ids: this.merchandiseIds,
          type: 16,
          methods: '{getMerchandisePictureLinks,getMerchandiseStyles}'
        }
      ]
      $.ajax({
        type: "POST",
        async: true,
        url: getMerchandiseInfoApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            const merchandisesInfo = res.data[0].objects
            const merchBeloneToStoreId = merchandisesInfo[0].storeId // 記錄這批商品原本所屬的賣場
            const merchIsBeloneToYourStore = vm.checkMerchBeloneToYourStore(merchBeloneToStoreId) // 檢查這些商品是否屬於自己擁有的賣場的
            vm.sortoutCopyMerchandise(merchandisesInfo, merchIsBeloneToYourStore)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 檢查這些商品是否屬於自己擁有的賣場的
    checkMerchBeloneToYourStore(merchBeloneToStoreId) {
      const merchIsBeloneToYourStore = this.ownerStoreIds.some(storeId => {
        return storeId == merchBeloneToStoreId
      })
      return merchIsBeloneToYourStore
    },
    // 整理要複製的商品
    sortoutCopyMerchandise(merchandisesInfo, merchIsBeloneToYourStore) {
      merchandisesInfo.forEach(item => {
        let object = JSON.parse(JSON.stringify(this.merchandiseObject()))
        // 紀錄商品名稱
        object.merchandise.name = item.name
        // 紀錄描述
        object.merchandise.description = item.description
        // 紀錄圖片
        item.merchandisePictureLinks.forEach((pic, index) => {
          object.pictureLinks.push({
            id: `p_${new Date() * 1 * index}`,
            url: handleImageHash2Url(pic.imageHash),
            name: null,
            home: false,
            files: null,
            front: pic.front,
            type: null,
          })
        })
        // 紀錄款式
        object.mainStyles = []
        item.merchandiseStyles.forEach((style, index) => {
          if (!style.deleted) {
            if (style.hierarchy === 0) {
              object.mainStyles.push({
                id: `ms_${new Date() * 1 + index}`,
                name: style.name,
                cost: merchIsBeloneToYourStore ? style.cost : 0, // 若商品本身就屬於自己賣場的，就預設帶入成本
                price: style.price ? this.$methods.numberToFixed(style.price) : 0,
              })
            } else if (style.hierarchy === 1) {
              object.oneStyles.push({
                id: `oneStyles_${new Date() * 1 + index}`,
                name: style.name,
              })
            } else if (style.hierarchy === 2) {
              object.twoStyles.push({
                id: `twoStyles_${new Date() * 1 + index}`,
                name: style.name,
              })
            } else if (style.hierarchy === 3) {
              object.threeStyles.push({
                id: `threeStyles_${new Date() * 1 + index}`,
                name: style.name,
              })
            } else if (style.hierarchy === 4) {
              object.fourStyles.push({
                id: `fourStyles_${new Date() * 1 + index}`,
                name: style.name,
              })
            }
          }
        })
        this.combineStyles(object)
        this.merchandises.push(object)
      })
      this.$methods.switchLoading('hide')
      this.loadingRecord = -1
    },
    // * 複製商品 (end)
    // * 建立擴充文章 (start)
    // 取得 localstorage 內的文章資訊
    getExtentionsPost() {
      const check = setInterval(() => {
        console.log('從 localstorage 取 FB 文章中...')
        this.postInfo = JSON.parse(localStorage.getItem(this.fbPostId))
        if (this.postInfo) clearInterval(check) 
      }, 1)
    },
    // 整理文章資訊
    sortoutPostInfo() {
      this.$methods.switchLoading('show')
      console.log(this.postInfo)
      this.merchandises = []
      let object = JSON.parse(JSON.stringify(this.merchandiseObject()))
      // 紀錄文章連結
      object.permalink_url = this.postInfo.post_url
      // 從文章中提出商品名稱
      let result = this.filterMerchandiseNameFromDescription(this.postInfo.post_content)
      let merchandiseName = result[0]
      let description = result[1]
      // 處理商品名稱
      if (merchandiseName.length > 100) merchandiseName.substring(0, 99)
      object.merchandise.name = merchandiseName
      // 處理商品描述
      if (description.length > 2000) description.substring(0, 1999)
      // 將商品描述內容取出款式細項
      this.filterStylesFromDescription(description, object)
      // 處理圖片
      this.postInfo.post_images.forEach((imgUrl, index) => {
        object.pictureLinks.push({
          id: `p_${new Date() * 1 * index}`,
          url: imgUrl,
          name: null,
          home: false,
          files: null,
          front: false,
          type: null,
        })
      })
      // 處理文章 url
      if (this.postInfo.post_url) object.fbFeedUrl = this.postInfo.post_url
      this.combineStyles(object)
      this.merchandises.push(object)
      this.loadingRecord = -1
      this.$methods.switchLoading('hide')
    },
    // * 建立擴充文章 (end)
    // * 建立 FB 文章 (start)
    // 取得 FB 貼文
    syncFbMerchIntoStore(searchData, timeRages) {
      this.$methods.switchLoading('show')
      const vm = this;
      const syncFbMerchIntoStoreApi = `${process.env.VUE_APP_API}/apps/fb/getFeedsInCommunity`;
      const header = {
        authorization: this.serverToken
      };
      this.feeds = []
      this.merchandises = []
      this.loadingRecord = timeRages.length
      timeRages.forEach(item => {
        const data = [{
          communityType: searchData.communityType,
          filterOwner: searchData.filterOwner,
          storeId: searchData.storeId,
          timeScope: {
            since: new Date(item.startTime) * 1,
            until: new Date(item.endTime) * 1
          }
        }]
        $.ajax({
          type: "POST",
          async: true,
          url: syncFbMerchIntoStoreApi,
          headers: header,
          data: JSON.stringify(data),
          contentType: 'application/json',
          success: function (res) {
            console.log(res);
            if (res.code === '200') {
              const feedInfo = res.data[0].feeds
              feedInfo.forEach(item => { vm.feeds.push(JSON.parse(JSON.stringify(item))) })
              vm.loadingRecord --
            } else {
              let errorMsg = null
              res.errorData.forEach(item => {
                if (item.message === "fb錯誤訊息: Error validating access token: The session has been invalidated because the user changed their password or Facebook has changed the session for security reasons.") {
                  errorMsg = '您的 FB 帳號似乎改過密碼，或是 FB 覺得您的帳號不安全，改掉了您的帳號授權，因此您只要返回登入頁面，再用 FB 登入一次系統即可'
                }
              })
              if (errorMsg) vm.SweetAlert(res.code, errorMsg)
              else vm.SweetAlert(res.code, res.message)
            }
          },
          error: function(err) {
            console.log(err.responseJSON)
            if (err.responseJSON) {
              vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
            }
            vm.$methods.switchLoading('hide')
          },
        });
      })
    },
    // 整理 FB 貼文
    sortoutFeeds() {
      // 如果沒有文章就結束
      if (this.feeds.length === 0) {
        this.loadingRecord --
        this.$methods.switchLoading('hide')
        return
      }
      this.feeds.sort((a, b) => {
        const a1 = new Date(a.created_time) * 1
        const b1 = new Date(b.created_time) * 1
        return b1 - a1
      })
      // 有文章就繼續處理
      this.mergeFeedsIntoBatchCreate()
    },
    // 將文章放進批次建立商品欄位內
    mergeFeedsIntoBatchCreate() {
      this.feeds.forEach((item, index) => {
        console.log(item)
        // 特殊處理: 如果是同步社團文章，若他的社團設定內 > 自定社團 > 網址，有設定的話，他的 url 內 groupId 會不見
        if (this.communityType === 35) {
          const groupId = item.id.split("_")[0]
          const feedId = item.id.split("_")[1]
          item.permalink_url = `https://www.facebook.com/groups/${groupId}/permalink/${feedId}/`
        }
        // 整理圖片
        item.img = []
        if (item.attachments && (item.attachments.data[0].subattachments || item.attachments.data[0].media)) {
          if (item.attachments.data[0].subattachments) {
            item.attachments.data[0].subattachments.data.forEach(fbImg => {
              item.img.push(fbImg.media.image.src)
            })
          } else {
            item.img.push(item.attachments.data[0].media.image.src)
          }
        }
        // 新增選項
        item.createFeedLink = true
        item.autoCreateMerchOrderFromFb = true
        let object = JSON.parse(JSON.stringify(this.merchandiseObject()))
        object.id *= index
        // 檢查是否有留言
        object.hasComments = item.comments && item.comments.data.length > 0
        // 紀錄文章連結
        object.permalink_url = item.permalink_url
        // 從文章中提出商品名稱
        let result = this.filterMerchandiseNameFromDescription(item.message)
        let merchandiseName = result[0]
        item.message = result[1]
        console.log(merchandiseName, item.message)
        let description = item.message
        // 處理商品名稱
        if (merchandiseName.length > 100) merchandiseName.substring(0, 99)
        object.merchandise.name = merchandiseName
        // 處理商品描述
        if (description.length > 2000) description.substring(0, 1999)
        // 將商品描述內容取出款式細項
        this.filterStylesFromDescription(description, object)
        // 處理圖片
        if (item.img.length > 0) {
          item.img.forEach((imgUrl, index) => {
            object.pictureLinks.push({
              id: `p_${new Date() * 1 * index}`,
              url: imgUrl,
              name: null,
              home: false,
              files: null,
              front: false,
              type: null,
            })
          })
        }
        // 處理文章 url
        if (item.permalink_url) object.fbFeedUrl = item.permalink_url
        this.combineStyles(object)
        this.merchandises.push(object)
      })
      this.loadingRecord --
      this.$methods.switchLoading('hide')
    },
    // 將文章取出商品名稱
    filterMerchandiseNameFromDescription(message) {
      console.log(message)
      // 以 ( **商品名稱** ) 格式為主，換行為輔
      let merchandiseName = message.match(/\*{2}[^\*]+\*{2}/gm) ? message.match(/\*{2}[^\*]+\*{2}/gm)[0] : null
      console.log(merchandiseName)
      if (!merchandiseName) merchandiseName = message.substring(0, message.indexOf('\n'))
      return [merchandiseName.replace(/\*\*/gm, ''), message.replace(merchandiseName, '')]
    },
    // 將描述取出款式細項
    filterStylesFromDescription(description, object) {
      let mainStylePrice = description.match(/\${2}\d+/gm) ? description.match(/\${2}\d+/gm)[0] : null // 取出 $$ 開頭的
      if (mainStylePrice) {
        // 若有 $$ 開頭的，就是用系統預設款式，帶入金額即可
        console.log(mainStylePrice)
        let newDescription = description.replace(mainStylePrice, '')
        let price = parseInt(mainStylePrice.replace('$$', ''))
        object.mainStyles[0].price = this.$methods.numberToFixed(price)
        object.merchandise.description = newDescription
      } else {
        // 沒有的話照舊
        let mainStyles = description.match(/\{.[^\{\}]+\}/gm)
        let detailStyles = description.match(/\[.[^\[\]]+\]/gm)
        // 若描述中有主要款式
        if (mainStyles && mainStyles.length > 0) {
          mainStyles.forEach((item, index) => {
            let filterString = item.substring(1, item.length - 1)
            let name = ''
            let price = filterString.split(':')[1] ? filterString.split(':')[1] : 0
            // 判斷主要款式是否超過 20 字
            if (filterString.split(':')[0] && filterString.split(':')[0].length <= 20) name = filterString.split(':')[0]
            else if (filterString.split(':')[0] && filterString.split(':')[0].length > 20) {
              name = filterString.split(':')[0].substring(0, 19)
            }
            object.mainStyles.push({
              id: `ms_${new Date() * 1 + index}`,
              name: name,
              cost: 0,
              price: this.$methods.numberToFixed(price) ? this.$methods.numberToFixed(price) : 0,
            })
          })
          // 若有主要款式，就將預設的拿掉
          if (object.mainStyles[0].name === '一個' && object.mainStyles[0].price === 0 && object.mainStyles[0].cost === 0 && object.mainStyles.length > 1) object.mainStyles = object.mainStyles.filter((item, index) => {return index !== 0})
        }
        // 若描述中有其他細項
        if (detailStyles && detailStyles.length > 0) {
          // 用來記錄現在在做哪個細項
          let countIndex = 1
          detailStyles.forEach(item => {
            // 去頭尾的 "["，"]"
            let filterString = item.substring(1, item.length - 1)
            // 取細項字串
            let detailStylesArray = filterString.split('，')
            if (detailStylesArray && detailStylesArray.length > 0 && countIndex <= 4) {
              // 取出來的細項陣列
              console.log(detailStylesArray)
              detailStylesArray.forEach((detailName, index) => {
                let name = ''
                // 判斷細項字數是否大於 20
                if (detailName && detailName.length <= 20) name = detailName
                else if (detailName && detailName.length > 20) name = detailName.substring(0, 19)
                console.log(countIndex, detailName)
                if (countIndex === 1) {
                  object.oneStyles.push({
                    id: `oneStyles_${new Date() * 1 + index}`,
                    name: name,
                  })
                } else if (countIndex === 2) {
                  object.twoStyles.push({
                    id: `twoStyles_${new Date() * 1 + index}`,
                    name: name,
                  })
                } else if (countIndex === 3) {
                  object.threeStyles.push({
                    id: `threeStyles_${new Date() * 1 + index}`,
                    name: name,
                  })
                } else if (countIndex === 4) {
                  object.fourStyles.push({
                    id: `fourStyles_${new Date() * 1 + index}`,
                    name: name,
                  })
                }
              })
              countIndex ++
            }
          })
        }
        let newDescription = description.replace(/(\n?\[.[^\[\]]+\])|(\n?\{.[^\{\}]+\})/gm, '')
        object.merchandise.description = newDescription
      }
    },
    // * 建立 FB 文章 (end)
    // * 商品 (start)
    // 新增欄位
    addField() {
      this.merchandises.push(this.merchandiseObject())
      this.combineStyles(this.merchandises[this.merchandises.length - 1])
      // 檢查目前頁數 (若新增欄位則幫他把目前所在頁數調到最後一頁)
      this.currentIndex = this.merchandises.length
      // 將畫面移至最上方
      this.scollToTop()
      this.SweetAlert('200')
    },
    // 複製商品
    copyMerhandise(merchandise) {
      console.log(merchandise)
      this.copyData.merchandise = {}
      this.copyData.merchandise = JSON.parse(JSON.stringify(merchandise))
      this.SweetAlert('200')
    },
    // 貼上商品
    pasteMerchandise(merchandise) {
      const copyData = JSON.parse(JSON.stringify(this.copyData.merchandise))
      console.log(copyData)
      this.merchandises.forEach(item => {
        if (item.id === merchandise.id) {
          item.id = new Date() * 1
          item.supplierStatus = copyData.supplierStatus
          item.deadLineTimeSwitch = copyData.deadLineTimeSwitch
          item.arrivalTimeSwitch = copyData.arrivalTimeSwitch
          item.merchandise = copyData.merchandise
          item.pictureLinks = []
          copyData.pictureLinks.forEach((img, index) => {
            // * 圖片的 files 因為深拷貝的關西，他是會變空物件的，所以要在這邊利用預覽 URL (base64) 去做解析並 new 一個新的 file 才行
            const array = img.url.split(',')
            const type = array[0]
            const bitsString = atob(array[1])
            let n = bitsString.length
            const u8arr = new Uint8Array(n)
            while(n--){
              u8arr[n] = bitsString.charCodeAt(n);
            }
            const rendom = Math.random().toString().split("."); // 檔名要用
            // * new file 所需的資料製作結束
            item.pictureLinks.push({
              files: new File([u8arr], `${rendom[1]}.${img.type}`, {type: type}),
              front: img.front,
              home: img.home,
              name: img.name,
              url: img.url,
              id: `p_${new Date() * 1 + index}`,
              type: img.type
            })
          })
          item.merchClassificationIds = []
          item.merchClassificationIds = copyData.merchClassificationIds
          item.supplierLinks = copyData.supplierLinks
          item.styles = []
          item.styles = copyData.styles
          item.styles.forEach((style, index) => {
            style.id = `ms_${new Date() * 1 + index}`
          })
          item.stocks = []
          item.stocks = copyData.stocks
          item.mainStyles = []
          item.mainStyles = copyData.mainStyles
          item.mainStyles.forEach((style, index) => {
            style.id = `ms_${new Date() * 1 + index}`
          })
          item.oneStyles = []
          item.oneStyles = copyData.oneStyles
          item.oneStyles.forEach((style, index) => {
            style.id = `oneStyles_${new Date() * 1 + index}`
          })
          item.twoStyles = []
          item.twoStyles = copyData.twoStyles
          item.twoStyles.forEach((style, index) => {
            style.id = `twoStyles_${new Date() * 1 + index}`
          })
          item.threeStyles = []
          item.threeStyles = copyData.threeStyles
          item.threeStyles.forEach((style, index) => {
            style.id = `threeStyles_${new Date() * 1 + index}`
          })
          item.fourStyles = []
          item.fourStyles = copyData.fourStyles
          item.fourStyles.forEach((style, index) => {
            style.id = `fourStyles_${new Date() * 1 + index}`
          })
          item.recordLength = copyData.recordLength
          item.conbineStylesErrorMsg = copyData.conbineStylesErrorMsg
        }
      })
      console.log(this.merchandises)
    },
    // 刪除商品
    delMerchandise(merchandiseId) {
      if (this.currentPage !== 'SynchronizeFBArticle') {
        // 批次建立商品頁面
        if (this.merchandises.length === 1) return this.SweetAlert('other', '至少要留一筆商品欄位')
      }
      // 檢查目前目前所在頁數 (若是在最後一頁刪除欄位，則幫他把目前頁數改成上一頁)
      if (this.currentIndex === this.merchandises.length) this.currentIndex -= 1
      // 如果是 FB 文章建立商品，就可以允許刪到最後
      this.merchandises = this.merchandises.filter(item => {return item.id !== merchandiseId})
      this.SweetAlert('200')
    },
    // * 商品 (end)
    // * 圖片 (start)
    // 預覽圖片
    priviewPicture(merchandise, id) {
      let files = document.getElementById(id).files;
      const handledResults = this.handleImages(files)
      this.pic_errorSize = handledResults.errorSize
      this.pic_errorType = handledResults.errorType
      handledResults.newImages.forEach(item => {
        console.log(item)
        const reader = new FileReader()
        reader.readAsDataURL(item.files)
        reader.onload = (e) => {
          merchandise.pictureLinks.push({
            id: `p_${new Date() * 1}`,
            url: e.target.result,
            name: item.name,
            home: false,
            files: item.files,
            front: false,
            type: item.type,
          })
        }
      })
    },
    // 首圖
    setFront(img, merchandise) {
      merchandise.pictureLinks.forEach(item => {
        if (item.id === img.id) item.front = true
        else item.front = false
      })
    },
    // 刪除圖片
    delImg(img, merchandise) {
      merchandise.pictureLinks = merchandise.pictureLinks.filter(item => {
        return item.id !== img.id
      })
    },
    // * 圖片 (end)
    // * 款式 (start)
    // 新增款式欄位
    createStyles(whitchOne, merchandise) {
      if (whitchOne === 'mainStyles') {
        merchandise[whitchOne].push({
          id: `ms_${new Date() * 1}`,
          name: '一個',
          cost: this.$methods.numberToFixed(merchandise.supplierLinks.cost),
          price: 0,
        })
      } else {
        merchandise[whitchOne].push({
          id: `${whitchOne}_${new Date() * 1}`,
          name: '',
        })
      }
    },
    // 刪除款式欄位
    delStyles(whitchOne, merchandise, styleId) {
      if (whitchOne === 'mainStyles') {
        if (merchandise[whitchOne].length === 1) return this.SweetAlert('other', '至少要有一筆主要款式')
      } else if (whitchOne === 'oneStyles') {
        if (merchandise.twoStyles.length > 0 && merchandise.oneStyles.length === 1) return this.SweetAlert('other', '細項二尚有款式')
      } else if (whitchOne === 'twoStyles') {
        if (merchandise.threeStyles.length > 0 && merchandise.twoStyles.length === 1) return this.SweetAlert('other', '細項三尚有款式')
      } else if (whitchOne === 'threeStyles') {
        if (merchandise.fourStyles.length > 0 && merchandise.threeStyles.length === 1) return this.SweetAlert('other', '細項四尚有款式')
      }
      merchandise[whitchOne] = merchandise[whitchOne].filter(item => {
        return item.id !== styleId
      })
    },
    // 複製款式
    copyStyles(whitchOne, merchandise) {
      if (whitchOne === 'mainStyles') {
        this.copyData[whitchOne] = []
        merchandise.mainStyles.forEach((item, index) => {
          if (item.name) {
            this.copyData[whitchOne].push({
              id: `ms_${new Date() * 1 + index}`,
              name: item.name,
              cost: item.cost ? this.$methods.numberToFixed(item.cost) : 0,
              price: item.price ? this.$methods.numberToFixed(item.price) : 0,
            })
          }
        })
      } else {
        if (merchandise[whitchOne].length === 0) return this.SweetAlert('other', '您尚未有內容可以複製')
        this.copyData.styles = []
        merchandise[whitchOne].forEach((item, index) => {
          if (item.name) {
            this.copyData.styles.push({
              id: `${whitchOne}_${new Date() * 1 + index}`,
              name: item.name,
            })
          }
        })
      }
      this.SweetAlert('200')
    },
    // 貼上款式
    pasteStyles(whitchOne, merchandise) {
      if (whitchOne === 'mainStyles') {
        if (this.copyData[whitchOne].length === 0) return this.SweetAlert('other', '您尚未有複製的內容')
        this.copyData[whitchOne].forEach((item, index) => {
          merchandise[whitchOne].push({
            id: `ms_${new Date() * 1 + index}`,
            name: item.name,
            cost: item.cost ? this.$methods.numberToFixed(item.cost) : 0,
            price: item.price ? this.$methods.numberToFixed(item.price) : 0,
          })
        })
      } else {
        if (this.copyData.styles.length === 0) return this.SweetAlert('other', '您尚未有複製的內容')
        this.copyData.styles.forEach((item, index) => {
          merchandise[whitchOne].push({
            id: `${whitchOne}_${new Date() * 1 + index}`,
            name: item.name,
          })
        })
      }
      this.SweetAlert('200')
    },
    // 批次新增自行輸入的款式
    batchAddOwnStyles(whitchOne, merchandise) {
      if (!merchandise[`${whitchOne}BatchAddOwn`]) return this.SweetAlert('other', '您尚未輸入任何內容')
      const styles = merchandise[`${whitchOne}BatchAddOwn`].replace(/\s*/g, "").split("，")
      // 檢查是否每項款式是否符合 20 字內
      const error = styles.some(item => {
        if (item.length > 20) {
          this.SweetAlert('other', `${item}，已超過20字`)
          return true
        }
      })
      if (error) return
      // 過濾重複值
      const filterRepeatResult = styles.filter((item, index, arr) => {
        return arr.indexOf(item) === index
      })
      filterRepeatResult.forEach((item, index) => {
        if (item) {
          if (whitchOne === 'mainStyles') {
            merchandise[whitchOne].push({
              id: `ms_${new Date() * 1 + index}`,
              name: item,
              cost: merchandise.mainStyleCost ? merchandise.mainStyleCost : 0,
              price: merchandise.mainStylePrice ? merchandise.mainStylePrice : 0,
            })
          } else {
            merchandise[whitchOne].push({
              id: `${whitchOne}_${new Date() * 1 + index}`,
              name: item,
            })
          }
        }
      })
      if (whitchOne === 'mainStyles') {
        merchandise.mainStyleCost = merchandise.supplierLinks.cost ? merchandise.supplierLinks.cost : ''
        merchandise.mainStylePrice = ''
      }
      merchandise[`${whitchOne}BatchAddOwn`] = ''
    },
    // 加逗號
    addComma(where, merchandise, recordString) {
      try {
        const el = document.querySelector(`#${where}`)
        const startCaret  = el.selectionStart // 起始游標位置
        const endCaret = el.selectionEnd // 結束游標位置
        if (!merchandise[recordString]) return
        merchandise[recordString] = merchandise[recordString].substring(0, endCaret) + '，' + merchandise[recordString].substring(endCaret)
      } catch (error) {
        console.log(error)
        alert('此功能有誤，請通報客服')
      }
    },
    // 批次更新單價 / 成本
    batchUpdatePriceOrCost(whitchOne, merchandise) {
      merchandise.mainStyles.forEach(style => {
        style[whitchOne] = this.$methods.numberToFixed(merchandise[`batchUpdateValue_${whitchOne}`])
      })
      this.combineStyles(merchandise)
    },
    // 來自<批次建立系統預設款式>子層的通知
    notifyParentFromD4SystemCanvas(object) {
      console.log(object)
      if (object.closeCanvas) this.showD4SystemCanvas = false
      if (object.finalStyles.length > 0) {
        this.batchAddOwnStylesData.forWitchMerchandise.mainStylesBatchAddOwn = ''
        object.finalStyles.forEach((item, index, arr) => {
          if (this.batchAddOwnStylesData.forWhitchStyles === 'mainStyles') {
            // 若是主要款式，就不要直接放進欄位內，而是放在輸入框內
            this.batchAddOwnStylesData.forWitchMerchandise.mainStylesBatchAddOwn += item
            if (index !== arr.length - 1) this.batchAddOwnStylesData.forWitchMerchandise.mainStylesBatchAddOwn += '，'
          } else {
            // 批次新增系統預設款式
            this.batchAddOwnStylesData.forWitchMerchandise[this.batchAddOwnStylesData.forWhitchStyles].push({
              id: `${this.batchAddOwnStylesData.forWhitchStyles}_${new Date() * 1 + index}`,
              name: item,
            })
          }
        })
      }
    },
    // 排列組合款式 (觸發條件: 陣列長度改變，輸入名稱欄位監聽)
    // 注意! 所以主要款式成本與單價變更要另外監聽處理，不要再讓他跑一次這支程式
    combineStyles(merchandise) {
      console.log(merchandise)
      merchandise.stocks = []
      merchandise.styles = []
      merchandise.mainStyles.forEach(style => {
        if (style.name) {
          merchandise.styles.push({
            id: style.id,
            hierarchy: 0,
            name: style.name,
            price: style.price ? this.$methods.numberToFixed(style.price) : 0,
            cost: style.cost ? this.$methods.numberToFixed(style.cost) : 0
          })
        }
      })
      if (merchandise.oneStyles.length > 0) {
        merchandise.oneStyles.forEach(style => {
          if (style.name) {
            merchandise.styles.push({
              id: style.id,
              hierarchy: 1,
              name: style.name
            })
          }
        })
      }
      if (merchandise.twoStyles.length > 0) {
        merchandise.twoStyles.forEach(style => {
        if (style.name) {
          merchandise.styles.push({
            id: style.id,
            hierarchy: 2,
            name: style.name
          })
        }
      })
      }
      if (merchandise.threeStyles.length > 0) {
        merchandise.threeStyles.forEach(style => {
          if (style.name) {
            merchandise.styles.push({
              id: style.id,
              hierarchy: 3,
              name: style.name
            })
          }
        })
      }
      if (merchandise.fourStyles.length > 0) {
        merchandise.fourStyles.forEach(style => {
          if (style.name) {
            merchandise.styles.push({
              id: style.id,
              hierarchy: 4,
              name: style.name
            })
          }
        })
      }
      
      // 檢查款式階層是否正確
      merchandise.conbineStylesErrorMsg = ''
      merchandise.styles.some((style, index, array) => {
        if (array.length > 1 && index < array.length - 1) {
          if (style.hierarchy === 0) {
            if (!((style.hierarchy === 0 && array[index + 1].hierarchy === 0) || (style.hierarchy === 0 && array[index + 1].hierarchy === 1))) {
              merchandise.conbineStylesErrorMsg = '細項一必須要有內容才能有後續的細項'
              return true
            }
          } else if (style.hierarchy === 1) {
            if (!((style.hierarchy === 1 && array[index + 1].hierarchy === 1) || (style.hierarchy === 1 && array[index + 1].hierarchy === 2))) {
              merchandise.conbineStylesErrorMsg = '細項二必須要有內容才能有後續的細項'
              return true
            }
          } else if (style.hierarchy === 2) {
            if (!((style.hierarchy === 2 && array[index + 1].hierarchy === 2) || (style.hierarchy === 2 && array[index + 1].hierarchy === 3))) {
              merchandise.conbineStylesErrorMsg = '細項三必須要有內容才能有後續的細項'
              return true
            }
          }
        }
      })

      console.log(merchandise.conbineStylesErrorMsg)
      if (!merchandise.conbineStylesErrorMsg) {
        const array2D = convertStyleTo2D(merchandise.styles)
        const serialArr = serialArray(array2D)
        console.log(serialArr)
        let stocksMap = new Map()
        serialArr.forEach(item => {
          let styles = []
          item.forEach(style => {
            styles.push({
              hierarchy: style.hierarchy,
              name: style.name
            })
          })
          if (!stocksMap.has(item[0].id)) {
            stocksMap.set(item[0].id, [{
              stock: {
                quantity: 0,
                limitedQuantity: 0
              },
              styles: styles
            }])
          } else {
            stocksMap.get(item[0].id).push({
              stock: {
                quantity: 0,
                limitedQuantity: 0
              },
              styles: styles
            })
          }
        })
        for (const [key, value] of stocksMap) {
          value.forEach(item => { merchandise.stocks.push(item) })
        }
        console.log(merchandise.stocks)
      }
    },
    // 更新主要款式價格與成本 (直接更新 merchandise > styles 陣列)
    updateStyles(mainStyleInfo, merchandise) {
      merchandise.styles.some(item => {
        if (item.id === mainStyleInfo.id) {
          item.price = this.$methods.numberToFixed(mainStyleInfo.price)
          item.cost = this.$methods.numberToFixed(mainStyleInfo.cost)
          return true
        }
      })
    },
    // * 款式 (end)
    // 清除供應商資訊
    clearSupplierInfo(merchandise) {
      merchandise.supplierLinks = {
        cost: 0,
        name: '',
        preset: true
      }
      this.setD4CostInMainStyles(merchandise)
    },
    // 帶入供應商成本進主要款式
    setD4CostInMainStyles(merchandise) {
      merchandise.mainStyles.forEach(item => {
        item.cost = this.$methods.numberToFixed(merchandise.supplierLinks.cost)
      })
      merchandise.styles.some(item => {
        if (item.hierarchy === 0) item.cost = this.$methods.numberToFixed(merchandise.supplierLinks.cost)
        else return true
      })
      merchandise.mainStyleCost = this.$methods.numberToFixed(merchandise.supplierLinks.cost)
    },
    // 檢查款式是否重複
    checkStylesIsRepeat(merchandise) {
      let isRepeat = []
      let nameArray = []
      // 主要款式
      merchandise.mainStyles.forEach(item => { nameArray.push(item.name) })
      isRepeat = nameArray.filter((item, index, arr) => {
        return arr.indexOf(item) !== index
      })
      if (isRepeat.length > 0) return '主要款式名稱有重複'

      // 細項一
      nameArray = []
      merchandise.oneStyles.forEach(item => { nameArray.push(item.name) })
      isRepeat = nameArray.filter((item, index, arr) => {
        return arr.indexOf(item) !== index
      })
      if (isRepeat.length > 0) return '細項一名稱有重複'

      // 細項二
      nameArray = []
      merchandise.twoStyles.forEach(item => { nameArray.push(item.name) })
      isRepeat = nameArray.filter((item, index, arr) => {
        return arr.indexOf(item) !== index
      })
      if (isRepeat.length > 0) return '細項二名稱有重複'

      // 細項三
      nameArray = []
      merchandise.threeStyles.forEach(item => { nameArray.push(item.name) })
      isRepeat = nameArray.filter((item, index, arr) => {
        return arr.indexOf(item) !== index
      })
      if (isRepeat.length > 0) return '細項三名稱有重複'

      // 細項四
      nameArray = []
      merchandise.fourStyles.forEach(item => { nameArray.push(item.name) })
      isRepeat = nameArray.filter((item, index, arr) => {
        return arr.indexOf(item) !== index
      })
      if (isRepeat.length > 0) return '細項四名稱有重複'
      
      // 都正確
      return null
    },
    // 批次新增商品
    createMerchandises:_.debounce(function(createSingleMerchData) {
      if (this.loadingRecord > -1) return this.SweetAlert('other', '資料尚未取得完畢')
      if (this.merchandises.length === 0) return this.SweetAlert('other', '您未有任何商品可以建立')
      this.$methods.switchLoading('show')
      const vm = this;
      const createMerchandiseApi = `${process.env.VUE_APP_API}/merchandise/create`;
      const header = {
        authorization: this.serverToken
      };
      const data = new FormData()
      let imgIndex = 0
      let jsonString = []
      console.log(createSingleMerchData)
      const error = this.merchandises.some((item, index) => {
        let merchandise = {}
        let pictureLinks = []
        let styles = []
        let stocks = []
        let merchClassificationIds = []
        let supplierLinks = []
        // 若是單筆建立商品的話，就只做那筆就好，否則就全做
        if (!createSingleMerchData || (createSingleMerchData && createSingleMerchData.id === item.id)) {
          // 有商品名稱在做事
          if (item.merchandise.name) {
            // 檢查收單時間
            if (item.deadLineTimeSwitch && !item.merchandise.deadLineTime) {
              this.SweetAlert('other', `第${index + 1}個商品: 未設定收單時間`)
              this.$methods.switchLoading('hide')
              return true
            }
            // 檢查到貨時間
            if (item.arrivalTimeSwitch && !item.merchandise.arrivalTime) {
              this.SweetAlert('other', `第${index + 1}個商品: 未設定到貨時間`)
              this.$methods.switchLoading('hide')
              return true
            }
            if (!item.deadLineTimeSwitch) item.merchandise.deadLineTime = null
            if (!item.arrivalTimeSwitch) item.merchandise.arrivalTime = null
            item.merchandise.startSaleQuantity ? item.merchandise.startSaleQuantity = item.merchandise.startSaleQuantity : item.merchandise.startSaleQuantity = 0
            // 製作深拷貝是因為最後要更改時間格式，若不做則畫面上的時間顯示會錯誤
            merchandise = JSON.parse(JSON.stringify(item.merchandise))
            // 紀錄圖片
            if (item.pictureLinks.length > 0) {
              const hasFront = item.pictureLinks.some(img => { return img.front })
              if (this.currentPage === 'BatchCreateMerchandise') {
                // 批次建立商品頁面
                item.pictureLinks.forEach((img, index) => {
                  pictureLinks.push({
                    imageHash: imgIndex,
                    front: img.front,
                  })
                  imgIndex ++
                  data.append('files', img.files)
                })
              } else {
                // FB 文章建立商品頁面
                item.pictureLinks.forEach((img, index) => {
                  pictureLinks.push({
                    imageHash: img.url,
                    front: img.front,
                  })
                })
              }
              if (!hasFront) pictureLinks[0].front = true
            }
            // 確保款式組合沒問題
            if (item.conbineStylesErrorMsg) {
              this.SweetAlert('other', `第${index + 1}個商品: ${item.conbineStylesErrorMsg}`)
              this.$methods.switchLoading('hide')
              return true
            }
            // 檢查款式是否重複
            const repeatError = this.checkStylesIsRepeat(item)
            if (repeatError) {
              this.SweetAlert('other', `第${index + 1}個商品: ${repeatError}`)
              this.$methods.switchLoading('hide')
              return true
            }
            // 紀錄款式
            item.styles.forEach(style => {
              if (style.hierarchy === 0) {
                styles.push({
                  hierarchy: 0,
                  name: style.name,
                  price: style.price ?  this.$methods.numberToFixed(style.price) : 0,
                  cost: style.cost ? this.$methods.numberToFixed(style.cost) : 0
                })
              } else {
                styles.push({
                  hierarchy: style.hierarchy,
                  name: style.name,
                })
              }
            })
            // 紀錄庫存
            item.stocks.forEach(stock => {
              const limitedQuantity = stock.stock.limitedQuantity ? stock.stock.limitedQuantity : 0
              const quantity = stock.stock.quantity ? stock.stock.quantity : 0
              if (limitedQuantity > 0 || quantity > 0) {
                stocks.push({
                  stock: {
                    quantity: quantity,
                    limitedQuantity: limitedQuantity
                  },
                  styles: stock.styles
                })
              }
            })
            // 紀錄商品分類
            merchClassificationIds = item.merchClassificationIds
            // 紀錄供應商
            if (item.supplierStatus === '選擇現有供應商' || item.supplierStatus === '自行輸入供應商') {
              if (!item.supplierLinks.name) {
                this.SweetAlert('other', `第${index + 1}個商品: 供應商名稱不能為空`)
                this.$methods.switchLoading('hide')
                return true
              }
              supplierLinks.push({
                name: item.supplierLinks.name,
                cost: item.supplierLinks.cost ? this.$methods.numberToFixed(item.supplierLinks.cost) : 0,
                preset: true
              })
            }
            // 變更時間格式
            if (merchandise.deadLineTime) merchandise.deadLineTime = new Date(merchandise.deadLineTime) * 1
            if (merchandise.arrivalTime) merchandise.arrivalTime = new Date(merchandise.arrivalTime) * 1
            // FB 文章頁面的兩個設定
            let createFeedLink = item.createFeedLink
            let autoCreateMerchOrderFromFb = item.autoCreateMerchOrderFromFb
            let merchInfo = {
              merchandise,
              pictureLinks,
              styles,
              stocks,
              merchClassificationIds,
              supplierLinks,
            }
            if (this.currentPage === 'SynchronizeFBArticle' || this.currentPage === 'SyncFbExtentionArticle') {
              // 如果他要關聯文章，再多加以下兩個 key value
              if (createFeedLink) {
                merchInfo.fbFeedUrl = item.fbFeedUrl
                merchInfo.autoCreateMerchOrderFromFb = autoCreateMerchOrderFromFb
              }
            }
            jsonString.push(merchInfo)
          } else {
            // 沒有商品名稱就跳出
            this.$methods.switchLoading('hide')
            this.SweetAlert('other', `第${index + 1}個商品: 沒有商品名稱`)
            return true
          }
        }
      })
      console.log(jsonString)
      // 這裡的 error = true 代表是某個商品內部資料有誤
      if (error) return
      if (jsonString.length === 0 && !error) {
        this.$methods.switchLoading('hide')
        return
      }
      data.append('jsonString', JSON.stringify(jsonString))
      $.ajax({
        type: "POST",
        async: true,
        url: createMerchandiseApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            if (createSingleMerchData) {
              // 只做單筆商品
              vm.merchandises = vm.merchandises.filter(item => { return item.id !== createSingleMerchData.id })
              if (vm.merchandises.length > 0) vm.SweetAlert('200')
              else vm.$router.push(`/seller/store/${vm.storeId}/merchandise`)
            } else {
              // 全做
              if (vm.currentPage === 'CopyMerchandise') {
                alert('成功')
                window.close()
              } else vm.$router.push(`/seller/store/${vm.storeId}/merchandise`)
            }
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    }, 2000, {
      leading: true, // 在延遲開始前，立即調用
      trailing: false, // 在延遲結束前，保證不再調用
    }),
    close() {
      this.closePageModal.hide()
      window.close()
    },
    // 過濾商品分類 (商品分類設定，隱藏的、不在開放時間內的都不給選)
    merchClassificationsFilter(merchClassifications) {
      const now = new Date() * 1
      const merchClassificationsFilterResult = merchClassifications.filter((obj,key) => {
        return obj.hidden === false && (obj.openInterval === false || (obj.openInterval === true &&  obj.enableTime <= now && now <= obj.expirationTime))
      })
      return merchClassificationsFilterResult
    }
  },
}
</script>
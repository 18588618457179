<template>
  <div>
    <!-- 批次新增系統預設款式的 canvas -->
    <div
      class="offcanvas offcanvas-top"
      tabindex="-1"
      ref="D4SystemCanvas"
      style="height: 60%"
    >
      <div class="offcanvas-header">
        <p class="tw-border-start fw-bolder">批次新增</p>
        <button class="tw-btn tw-btn-success" @click="sendDataToParent">
          確定新增
        </button>
      </div>
      <div class="offcanvas-body">
        <!-- 列表 -->
        <ul class="nav nav-tabs">
          <li class="nav-item pointer" @click="switchPage('color')">
            <a
              class="nav-link pointer"
              :class="{ active: currentPage === 'color' }"
              >顏色</a
            >
          </li>
          <li class="nav-item pointer" @click="switchPage('size')">
            <a class="nav-link" :class="{ active: currentPage === 'size' }"
              >衣服尺寸</a
            >
          </li>
          <li class="nav-item pointer" @click="switchPage('number')">
            <a class="nav-link" :class="{ active: currentPage === 'number' }"
              >依照數字設定</a
            >
          </li>
          <li class="nav-item pointer" @click="switchPage('common')">
            <a class="nav-link" :class="{ active: currentPage === 'common' }"
              >常用款式</a
            >
          </li>
        </ul>
        <div class="pt-3">
          <!-- 顏色 -->
          <div v-if="currentPage === 'color'">
            <div
              class="form-check p-0"
              v-for="data in defaultStyles.colors"
              :key="data.index"
            >
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                :value="data"
                :id="`color${data.index}`"
                v-model="selectColors"
              />
              <label class="form-check-label" :for="`color${data.index}`">
                {{ data.value }}
              </label>
            </div>
          </div>
          <!-- 衣服尺寸 -->
          <div v-else-if="currentPage === 'size'">
            <div
              class="form-check p-0"
              v-for="data in defaultStyles.sizes"
              :key="data.index"
            >
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                :value="data"
                :id="`size${data.index}`"
                v-model="selectSizes"
              />
              <label class="form-check-label" :for="`size${data.index}`">
                {{ data.value }}
              </label>
            </div>
          </div>
          <!-- 依照數字設定 -->
          <div v-else-if="currentPage === 'number'">
            <!-- 上排 -->
            <div class="row">
              <div class="col-4">
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    id="firstNumber"
                    placeholder="僅限輸入數字"
                    v-model="batchCreateByNumber.startNumber"
                    @input="
                      batchCreateByNumber.startNumber = $methods.numberToFixed(
                        batchCreateByNumber.startNumber
                      )
                    "
                  />
                  <label for="firstNumber">起始數字</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    id="endNumber"
                    placeholder="僅限輸入數字"
                    v-model="batchCreateByNumber.endNumber"
                    @input="
                      batchCreateByNumber.endNumber = $methods.numberToFixed(
                        batchCreateByNumber.endNumber
                      )
                    "
                  />
                  <label for="endNumber">結束數字</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    id="betweenNumber"
                    placeholder="僅限輸入數字"
                    min="1"
                    v-model="batchCreateByNumber.betweenNumber"
                    @input="
                      batchCreateByNumber.betweenNumber =
                        $methods.numberToFixed(
                          batchCreateByNumber.betweenNumber
                        )
                    "
                  />
                  <label for="betweenNumber">數字間距</label>
                </div>
              </div>
            </div>
            <!-- 下排 -->
            <div class="row">
              <div class="col-4">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="insertFirstText"
                    placeholder="插入字首"
                    v-model="batchCreateByNumber.insertFirstText"
                  />
                  <label for="insertFirstText">插入字首</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="insertEndText"
                    placeholder="插入字尾"
                    v-model="batchCreateByNumber.insertEndText"
                  />
                  <label for="insertEndText">插入字尾</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    id="paddingDigits"
                    placeholder="補齊位數"
                    min="0"
                    v-model="batchCreateByNumber.paddingDigits"
                    pattern="[0-9]*"
                    oninput="this.value=this.value.replace(/\D/g,'')"
                  />
                  <label for="paddingDigits">補齊位數</label>
                </div>
              </div>
            </div>
            <!-- 顯示結果 -->
            <div class="mt-3">
              <p class="fw-bold mb-3">預覽結果</p>
              <span
                v-for="(data, index) in batchCreateByNumberResult"
                :key="index"
              >
                {{ data }}&ensp;
              </span>
            </div>
          </div>
          <!-- 常用款式 -->
          <div v-else-if="currentPage === 'common'">
            <p class="text-danger fw-bolder" v-if="p_commonStyles.p_loading">
              請稍後~正在為您準備資料中...
            </p>
            <div v-else>
              <template
                v-for="data in p_commonStyles.commonStyles"
                :key="data.id"
              >
                <div class="form-check p-0" v-if="data.selected">
                  <input
                    class="form-check-input m-0 me-2"
                    type="radio"
                    name="flexRadioDefault"
                    :id="`common${data.id}`"
                    :value="data.value"
                    v-model="commonStyle"
                  />
                  <label class="form-check-label" :for="`common${data.id}`">
                    {{ data.value }}
                  </label>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// mixin
import { MerchandiseStylesMap } from '../../methods/mixins/getSystemDefaultStyles'

export default {
  props: {
    showD4SystemCanvas: {
      type: Boolean,
      default: false
    },
  },
  emits: ['notifyParentFromD4SystemCanvas'],
  data() {
    return {
      // canvas
      D4SystemCanvas: {},
      // data
      storeId: 0,
      serverToken: '',
      currentPage: 'color',
      selectColors: [],
      selectSizes: [],
      // 批次新增依照數字
      batchCreateByNumber: {
        startNumber: 1,
        endNumber: 10,
        betweenNumber: 1,
        insertFirstText: "",
        insertEndText: "",
        paddingDigits: 0,
      },
      batchCreateByNumberResult: [],
      commonStyle: '',
    }
  },
  mixins: [MerchandiseStylesMap],
  created() {
    this.initialization()
    this.getPrefer()
  },
  mounted() {
    this.createOffcanvas(['D4SystemCanvas'])
  },
  computed: {
    ...mapState('Prefer', {
      p_loading: state => state.p_loading,
      p_commonStyles: state => state.p_commonStyles,
    })
  },
  watch: {
    showD4SystemCanvas(val) {
      if (val) {
        this.switchPage('color')
        this.D4SystemCanvas.show()
        this.$emit('notifyParentFromD4SystemCanvas', {
          finalStyles: [],
          closeCanvas: true
        })
      }
    },
    // 監聽批次新增 > 依照數字
    batchCreateByNumber: {
      handler(val) {
        this.batchCreateByNumberResult = [];
        let array = [];
        let startNumber = val.startNumber ? this.$methods.numberToFixed(val.startNumber) : 0;
        let endNumber = val.endNumber ? this.$methods.numberToFixed(val.endNumber) : 0;
        let betweenNumber = val.betweenNumber ? this.$methods.numberToFixed(val.betweenNumber) : 1;
        let insertZero = "00000";
        // 先將起始數字加入陣列
        array.push(startNumber);
        // 再來加入數字間距將後續數字加入陣列內
        let index = 0
        while (startNumber < endNumber) {
          index ++
          startNumber += betweenNumber;
          array.push(this.$methods.numberToFixed(startNumber));
          if (index > 30 ) break
        }
        console.log(array);
        array.forEach((item) => {
          // 判斷陣列內數字有沒有大於結束數字
          if (item <= endNumber) {
            let currentData = item.toString();
            // 補齊位數
            if (currentData.length < val.paddingDigits) {
              currentData = (
                currentData.slice(0, 1) +
                insertZero.slice(0, val.paddingDigits - currentData.length) +
                currentData
              ).slice("1");
            }
            // 插入字首
            currentData = (
              currentData.slice(0, 1) +
              val.insertFirstText +
              currentData
            ).slice(1);
            // 插入字尾
            currentData += val.insertEndText;
            this.batchCreateByNumberResult.push(currentData);
          }
        });
        // 判斷陣列長度，最大 20 筆
        if (this.batchCreateByNumberResult.length > 20) {
          this.batchCreateByNumberResult = [];
          this.batchCreateByNumberResult.push("筆數不得超過 20 筆!");
        }
        // 判斷每組款式字數長度不能大於 20
        const error = this.batchCreateByNumberResult.some(item => {
          return item.length > 20
        })
        if (error) {
          this.batchCreateByNumberResult = []
          this.batchCreateByNumberResult.push("款式字數超過20字")
        }
      },
      deep: true,
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    // 切換頁
    switchPage(page) {
      this.currentPage = page
      this.selectColors = []
      this.selectSizes = []
      this.batchCreateByNumber.startNumber = 0
      this.batchCreateByNumber = {
        startNumber: 1,
        endNumber: 10,
        betweenNumber: 1,
        insertFirstText: "",
        insertEndText: "",
        paddingDigits: 0,
      }
      this.commonStyle = ''
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 確定新增
    sendDataToParent() {
      let styles = []
      switch (this.currentPage) {
        case 'color':
          if (this.selectColors.length === 0) return this.SweetAlert('other', '您尚未選擇任何顏色款式')
          const arrayA = this.selectColors.sort((a, b) => {
            return a.index - b.index
          })
          arrayA.forEach(item => { styles.push(item.value) })
          break;
        case 'size':
          if (this.selectSizes.length === 0) return this.SweetAlert('other', '您尚未選擇任何顏色款式')
          const arrayB = this.selectSizes.sort((a, b) => {
            return a.index - b.index
          })
          arrayB.forEach(item => { styles.push(item.value) })
          break;
        case 'number':
          if (this.batchCreateByNumberResult[0] === '筆數不得超過 20 筆!' || this.batchCreateByNumberResult[0] === '款式字數超過20字')
            return this.SweetAlert('other', this.batchCreateByNumberResult[0])
          if (this.batchCreateByNumberResult.length === 0) return this.SweetAlert('other', '數字範圍輸入不完整')
          styles = this.batchCreateByNumberResult
          break;
        case 'common':
          if (!this.commonStyle) return this.SweetAlert('other', '您尚未選取任何常用款式')
          const arrayC = this.commonStyle.split('，')
          arrayC.forEach(item => {
            if (item.replace(/\s*/g, "")) styles.push(item.replace(/\s*/g, ""))
          })
          break;
      }
      this.$emit('notifyParentFromD4SystemCanvas', {
        finalStyles: styles,
        closeCanvas: true
      })
      this.D4SystemCanvas.hide()
    },
  },
}
</script>